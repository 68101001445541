import React, { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import Spinner from 'react-bootstrap/Spinner';
import LoaderButton from '../components/LoaderButton';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { API, Auth } from 'aws-amplify';
import SimpleReactValidator from 'simple-react-validator';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';


const providerId = 'e986bca0-ff41-11ea-8196-af25e422bd08';
const facilityId = 'ff1694c0-ffa8-11ea-bfbc-4f270cb046f3';
const maxTenancyInMonths = 36;
const currency = 'bgn';
const currencySymbol = {
  bgn: 'лв'
};


export default function CreateBooking() {
  const [redirect, setRedirect] = useState(undefined);
  const stripe = useStripe();
  const elements = useElements();
  const [user, setUser] = useState(undefined);
  const [userEmail, setUserEmail] = useState(undefined);
  useEffect(() => {
    if (!user) {
      Auth.currentAuthenticatedUser()
        .then(_user => {
          setUserEmail(_user.attributes.email);
          setUser(_user);
        })
        .catch(console.log);
    }
  });

  const [fields, handleFieldChange] = useState({
    name: '',
    vat_number: '',
    email: userEmail,
    telephone: '',
    address: '',
    from: moment().toISOString(),
    months: maxTenancyInMonths + 1,
    to: moment().add(maxTenancyInMonths + 1, 'M').toISOString(),
    unit: undefined,
    unitCategory: undefined,
    unitVariant: undefined,
    unitId: undefined,
    paymentFrequency: 'month',
    coupon: '',
    paymentMethod: 'card',
    termsAccepted: false
  });

  const [catalog, setCatalog] = useState(undefined);
  const [unitVariant, setUnitVariant] = useState(undefined);
  const [coupon, setCoupon] = useState({ code: undefined, name: undefined, valid: undefined, invalid: undefined, message: undefined, discount: { percent: 0, amount: 0 } });

  /* update the catalog
     - using availability from either:
       - the default rental time period
       - any from/to dates that have changed
     - preselect a category, variant and unit using either:
       - any unit that is held for the user
       - the first available unit in the preselected variant
     executes after:
     - the user email is determined
     - the rental time period has changed
  */
  useEffect(() => {
    if (!!userEmail) {
      API.get('limelockers', `/${providerId}/${facilityId}/catalog?from=${fields.from}&to=${fields.to}&email=${userEmail}`)
        .then(response => {
          setIsLoading(false);
          if (!!response.status && !!response.catalog) {
            const heldUnitExistsForUser = Object.keys(response.catalog).some(
              _unitCategory => Object.keys(response.catalog[_unitCategory]).some(
                _unitVariant => response.catalog[_unitCategory][_unitVariant].available.units.some(
                  _unit => !!_unit.held && _unit.held.for === userEmail)));
            const preselectedCategory = heldUnitExistsForUser
              ? Object.keys(response.catalog).find(
                  _unitCategory => Object.keys(response.catalog[_unitCategory]).some(
                    _unitVariant => response.catalog[_unitCategory][_unitVariant].available.units.some(
                      _unit => !!_unit.held && _unit.held.for === userEmail)))
              : Object.keys(response.catalog)[0];
            const preselectedVariant = heldUnitExistsForUser
              ? Object.keys(response.catalog[preselectedCategory]).find(
                    _unitVariant => response.catalog[preselectedCategory][_unitVariant].available.units.some(
                      _unit => !!_unit.held && _unit.held.for === userEmail))
              : Object.keys(response.catalog[preselectedCategory]).find(_variant => !!response.catalog[preselectedCategory][_variant].available.units.length);
            const preselectedUnitId = heldUnitExistsForUser
              ? response.catalog[preselectedCategory][preselectedVariant].available.units.find(_unit => !!_unit.held && _unit.held.for === userEmail).unitId
              : !!response.catalog[preselectedCategory][preselectedVariant].available.units.length
                ? response.catalog[preselectedCategory][preselectedVariant].available.units.sort((a, b) => (a.name > b.name) ? 1 : -1)[0].unitId
                : undefined;
            
            handleFieldChange(_fields => ({ ..._fields, unitId: preselectedUnitId }));
            handleFieldChange(_fields => ({ ..._fields, unit: `${preselectedCategory}/${preselectedVariant}` }));
            setCatalog(response.catalog);
            console.log(response);
          } else {
            console.log(response);
          }
        })
        .catch(console.log)
    }
  }, [userEmail, fields.from, fields.to]);

  /* executes after:
     - the catalog has been updated
     - a discount coupon has been applied
     - the payment frequency preference has changed
     - a different unit variant has been selected
  */
  useEffect(() => {
    if (!!catalog) {
      const [category, variant] = (!fields.unit/* || !fields.unitCategory || !fields.unitVariant*/)
        ? [Object.keys(catalog)[0], Object.keys(catalog[Object.keys(catalog)[0]]).find(_variant => !!catalog[Object.keys(catalog)[0]][_variant].available.units.length)]
        : fields.unit.split('/');
      const heldUnitExistsForUser = catalog[category][variant].available.units.some(_unit => !!_unit.held && _unit.held.for === userEmail);
      const preselectedUnitId = heldUnitExistsForUser
        ? catalog[category][variant].available.units.find(_unit => !!_unit.held && _unit.held.for === userEmail).unitId
        : !!catalog[category][variant].available.units.length
          ? catalog[category][variant].available.units.sort((a, b) => (a.name > b.name) ? 1 : -1)[0].unitId
          : undefined;
      handleFieldChange(_fields => ({ ..._fields, unitId: preselectedUnitId }));
      setUnitVariant(catalog[category][variant]);
      setPrice(priceCalculator(catalog[category][variant], fields.paymentFrequency, currency, coupon));
    }
  }, [userEmail, catalog, coupon, fields.paymentFrequency, fields.unit]);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVatNumberLookupRunning, setIsVatNumberLookupRunning] = useState(false);
  const [touched, setTouched] = useState({});
  const [focusedDate, setFocusedDate] = useState(false);
  const [showVatInput, setShowVatInput] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [validationSuccesses, setValidationSuccesses] = useState({});
  const [vatEntity, setVatEntity] = useState({countryCode: 'BG', vatNumber: '', name: '', address: ''});
  const useValidator = (customMessage = {}, customValidator = {}): [SimpleReactValidator/*, Function*/] => {
    const validatorRef = useRef(new SimpleReactValidator({
      messages: customMessage, 
      validators: customValidator,
    }));
    return [validatorRef];
  };
  const [validator] = useValidator();

  useEffect(() => {
    if (!!fields.coupon && fields.coupon.length > 9) {
      API.post('limelockers', '/validation/coupon', { body: { coupon: fields.coupon } })
        .then(response => {
          //console.log(response);
          if (!!response.error && (response.error.code === 'resource_missing')) {
            setCoupon(c => ({ ...c, invalid: true, valid: false, message: `Unrecognised discount code: ${fields.coupon}` }));
          } else if (!!response.coupon && response.coupon.valid) {
            setCoupon(c => ({
              ...c,
              name: response.coupon.name,
              discount: {
                percent: response.coupon.percent_off || 0,
                amount: response.coupon.amount_off || 0
              },
              invalid: false,
              valid: true,
              message: `Discount code applied: ${response.coupon.name}`
            }));
          }
        })
        .catch(console.log);
    }
  }, [fields.coupon]);

  const blankPrice = {
    id: undefined,
    currency: 'bgn',
    amount: {
      base: 0,
      subtotal: 0,
      total: 0
    },
    discount: {
      percent: 0,
      amount: 0
    },
    vat: {
      percent: 20,
      amount: 0
    }
  };
  const [price, setPrice] = useState(blankPrice);

  function priceCalculator(unitVariant, paymentFrequency, currency, coupon) {
    const base = unitVariant.product.price[paymentFrequency][currency].amount;
    const discount = (!!coupon.discount.percent)
      ? {
          percent: coupon.discount.percent,
          amount: (base * (coupon.discount.percent / 100))
        }
      : coupon.discount;
    const subtotal = (base - discount.amount);
    const vat = (!!unitVariant.product.price[paymentFrequency][currency].tax && !!unitVariant.product.price[paymentFrequency][currency].tax.VAT)
      ? {
          percent: unitVariant.product.price[paymentFrequency][currency].tax.VAT.percentage,
          amount: (subtotal * (unitVariant.product.price[paymentFrequency][currency].tax.VAT.percentage / 100))
        }
      : {
          percent: 0,
          amount: 0
        };
    const total = (subtotal + vat.amount);
    return {
      id: unitVariant.product.price[paymentFrequency][currency].id,
      currency: currency,
      amount: {
        base,
        baseWithVat: (base + vat.amount), // only used to show what price would have been without discount
        subtotal,
        total
      },
      discount,
      vat
    };
  }

  useEffect(() => {
    if (user && user.attributes) {
      handleFieldChange(f => ({
        ...f,
        name: (user.attributes.name) ? user.attributes.name : f.name,
        email: (user.attributes.email) ? user.attributes.email : f.email,
        address: (user.attributes.address) ? user.attributes.address : f.address,
        telephone: (user.attributes.phone_number) ? user.attributes.phone_number : f.telephone,
        vat_number: ('custom:vat_number' in user.attributes) ? user.attributes['custom:vat_number'] : f.vat_number,
      }));
      if ('custom:vat_number' in user.attributes) {
        // todo: rerun vat vies validation here.
        setShowVatInput(true);
        setVatEntity(e => ({
          countryCode: user.attributes['custom:vat_number'].slice(0, 2),
          vatNumber: user.attributes['custom:vat_number'].slice(2),
          valid: true,
          name: user.attributes.name,
          address: user.attributes.address
        }));
      }
    }
  }, [user]);
  useEffect(() => {
    setTouched(t => ({
      ...t,
      vat_number: true,
      name: true,
      address: true
    }));
  }, [vatEntity]);

  function validateForm() {
    return (
      (Object.keys(validationErrors).length === 0)
      && (validator.current.allValid())
      && (stripe && elements)
      && fields.termsAccepted
    );
  }

  function handleTabSelect(category) {
    //console.log(`handleTabSelect(${category})`);
    if (!fields.unit.startsWith(category)) {
      handleCardSelect(category, Object.keys(catalog[category]).find(variant => !!catalog[category][variant].available.units.length));
    }
  }

  function handleCardSelect(category, variant) {
    //console.log(`handleCardSelect(${category}, ${variant})`);
    if ((fields.unit !== `${category}/${variant}`) && (!!catalog[category][variant].available.units.length)) {
      handleFieldChange(f => ({...f, unit: `${category}/${variant}`}));
    }
  }

  async function handleVatNumberChange(event) {
    //let tidiedVatNumber = (event.target.value || '').trim().replace(/ /g, '').toUpperCase();
    const { id, value } = event.target;
    const countryCode = (id === 'countryCode') ? value : vatEntity.countryCode;
    const vatNumber = (id === 'vatNumber') ? value : vatEntity.vatNumber;
    setVatEntity({ countryCode, vatNumber });
    const vatNumberFormatValidators = {
      AT: /^ATU[A-Z0-9]{9,9}$/,
      BE: /^BE[0-9]{10,10}$/,
      BG: /^BG[0-9]{9,10}$/,
      CY: /^CY[A-Z0-9]{9,9}$/,
      CZ: /^CZ[0-9]{8,10}$/,
      DE: /^DE[0-9]{9,9}$/,
      DK: /^DK[0-9]{8,8}$/,
      EE: /^EE[0-9]{9,9}$/,
      EL: /^EL[0-9]{9,9}$/,
      ES: /^ES[A-Z0-9]{9,9}$/,
      FI: /^FI[0-9]{8,8}$/,
      FR: /^FR[A-Z0-9]{2,2}[0-9]{9,9}$/,
      GB: /^GB[0-9]{9,9}$|^GB[0-9]{12,12}$|^GBGD[0-9]{3,3}$|^GBHA[0-9]{3,3}$/,
      HU: /^HU[0-9]{8,8}$/,
      IE: /^IE[A-Z0-9]{8,8}$/,
      IT: /^IT[0-9]{11,11}$/,
      LT: /^LT[0-9]{9,9}$|^LT[0-9]{12,12}$/,
      LU: /^LU[0-9]{8,8}$/,
      LV: /^LV[0-9]{11,11}$/,
      MT: /^MT[0-9]{8,8}$/,
      NL: /^NL[A-Z0-9]{12,12}$/,
      PL: /^PL[0-9]{10,10}$/,
      PT: /^PT[0-9]{9,9}$/,
      SE: /^SE[0-9]{12,12}$/,
      SI: /^SI[0-9]{8,8}$/,
      SK: /^SK[0-9]{10,10}$/
    };
    if (`${countryCode}${vatNumber}`.match(vatNumberFormatValidators[countryCode])) {
      setValidationSuccesses({
        ...validationSuccesses,
        vat_number: `${countryCode}${vatNumber} matches a known ${countryCode} VAT number format.`
      });
      if ('vat_number' in validationErrors) {
        let {vat_number, ...validationErrorsWithVatNumberErrorsRemoved} = validationErrors;
        setValidationErrors(validationErrorsWithVatNumberErrorsRemoved);
      }
      setIsVatNumberLookupRunning(true);
      API.post('limelockers', '/validation/vat', { body: { countryCode, vatNumber } })
        .then(response => {
          setIsVatNumberLookupRunning(false);
          if (response.status && response.entity && response.entity.valid) {
            setValidationSuccesses({
              ...validationSuccesses,
              vat_number: `${countryCode}${vatNumber} is registered with VIES.`
            });
            if ('vat_number' in validationErrors) {
              let {vat_number, ...validationErrorsWithVatNumberErrorsRemoved} = validationErrors;
              setValidationErrors(validationErrorsWithVatNumberErrorsRemoved);
            }
            setVatEntity({
              valid: response.entity.valid,
              name: response.entity.name,
              address: response.entity.address,
              countryCode: response.entity.countryCode,
              vatNumber: response.entity.vatNumber
            });
          } else if (response.entity && !response.entity.valid) {
            setValidationErrors({
              ...validationErrors,
              vat_number: `${countryCode}${vatNumber} is not registered with VIES.`
            });
            if ('vat_number' in validationSuccesses) {
              let {vat_number, ...validationSuccessesWithVatNumberSuccessesRemoved} = validationSuccesses;
              setValidationSuccesses(validationSuccessesWithVatNumberSuccessesRemoved);
            }
          }
          else {
            console.log((response.error) ? response.error : response);
          }
        })
        .catch(console.log);
    } else {
      setValidationErrors({
        ...validationErrors,
        vat_number: `${countryCode}${vatNumber} does not match a known ${countryCode} VAT number format.`
      });
      if ('vat_number' in validationSuccesses) {
        let {vat_number, ...validationSuccessesWithVatNumberSuccessesRemoved} = validationSuccesses;
        setValidationSuccesses(validationSuccessesWithVatNumberSuccessesRemoved);
      }
    }
  }

  function createSubscription({ cancelAt, customerId, paymentMethodId, reservation, defaultTaxRates, coupon }) {
    const { priceId, unitCategoryId, unitVariantId, unitId, from, to } = reservation;
    const items = [
      {
        priceId,
        unitCategoryId,
        unitVariantId,
        unitId
      }
    ];
    return (
      API.put('limelockers', `/${providerId}/${facilityId}/subscription`, { body: { email: userEmail, cancelAt, customerId, items, paymentMethodId, reservation: { from, to }, defaultTaxRates, coupon } })
        // If the card is declined, display an error to the user.
        .then((response) => {
          if (!response.status && response.error) {
            throw response.error; // error associating payment method with customer
          }
          console.log(`[API.put (/${providerId}/${facilityId}/subscription) response]`, response);
          return response;
        })
        .then((response) => {
          return {
            paymentMethodId,
            reservation,
            defaultTaxRates,
            subscription: response.subscription
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // If attaching this card to a Customer object succeeds,
        // but attempts to charge the customer fail, you
        // get a requires_payment_method error.
        .then(handleRequiresPaymentMethod)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.

          // todo: implement error ui
          //showCardError(error);
          console.error(`[API.put (/${providerId}/${facilityId}/subscription) error]`, error)
        })
    );
  }

  function handlePaymentThatRequiresCustomerAction({ subscription, invoice, reservation, defaultTaxRates, paymentMethodId, isRetry }) {
    if (subscription && subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return {
        subscription,
        reservation,
        defaultTaxRates,
        paymentMethodId
      };
    }

    // if it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // if it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, { payment_method: paymentMethodId })
        .then((result) => {
          if (result.error) {
            // start code flow to handle updating the payment details.
            // display error message in your UI.
            // the card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === 'succeeded') {
              // show a success message to customer.
              // there's a risk of the customer closing the window before the callback. watch webhook endpoints to mitigate.
              return {
                subscription,
                reservation,
                defaultTaxRates,
                paymentMethodId,
                invoice
              };
            }
          }
        })
        .catch((error) => {
          
          // todo: implement error ui
          // displayError(error);
          console.log(error);
        });
    } else {
      // no customer action needed.
      return {
        subscription,
        reservation,
        defaultTaxRates,
        paymentMethodId
      };
    }
  }

  function onSubscriptionComplete(result) {
    // Payment was successful.
    if (result.subscription.status === 'active') {
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
    }
  }

  function handleRequiresPaymentMethod({ subscription, paymentMethodId, reservation, defaultTaxRates }) {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, paymentMethodId, reservation, defaultTaxRates };
    } else if (
      subscription.latest_invoice.payment_intent.status ===
      'requires_payment_method'
    ) {
      // Using localStorage to manage the state of the retry here,
      // feel free to replace with what you prefer.
      // Store the latest invoice ID and status.
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id);
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      );
      throw new Error('The card transaction was declined.');
    } else {
      return { subscription, paymentMethodId, reservation, defaultTaxRates };
    }
  }

  function retryInvoiceWithNewPaymentMethod({ customerId, invoiceId, paymentMethodId }) {
    return (
      API.post('limelockers', `/${providerId}/${facilityId}/invoice`, { body: { customerId, invoiceId, paymentMethodId } })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            // The card had an error when trying to attach it to a customer.
            throw result;
          }
          return result;
        })
        // Normalize the result to contain the object returned by Stripe.
        // Add the additional details we need.
        .then((result) => {
          return {
            // Use the Stripe 'object' property on the
            // returned result to understand what object is returned.
            invoice: result,
            paymentMethodId: paymentMethodId,
            isRetry: true,
          };
        })
        // Some payment methods require a customer to be on session
        // to complete the payment process. Check the status of the
        // payment intent to handle these actions.
        .then(handlePaymentThatRequiresCustomerAction)
        // No more actions required. Provision your service for the user.
        .then(onSubscriptionComplete)
        .catch((error) => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.

          // todo: implement error ui
          // displayError(error);
          console.log(error);
        })
    );
  }

  function parseStripeAddress(countryCode, viesAddress) {
    switch (countryCode) {
      case 'BG':
        return {
          line1: viesAddress.match(/^(.*)\sобл\./i)[1],
          city: viesAddress.match(/гр\.(.*)\s[0-9]+$/i)[1],
          state: viesAddress.match(/обл\.(.*),/i)[1],
          postal_code: viesAddress.match(/\s([0-9]*)$/i)[1],
          country: countryCode
        };
      default:
        return {
          line1: viesAddress,
          country: countryCode
        };
    }
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsSubmitting(true);
    // associate name, address, telephone, vat number with cognito user
    Auth.currentAuthenticatedUser().then(async(user) => {
      let { attributes } = user;
      if (!('custom:stripe_customer_id' in attributes)) {
        // todo: check for an existing customer, using email address
        const createCustomerResponse = await API.put('limelockers', `/${providerId}/${facilityId}/customer`, { body: { email: attributes.email } });
        if (createCustomerResponse.status && createCustomerResponse.customer && createCustomerResponse.customer.id) {
          await Auth.updateUserAttributes(user, { 'custom:stripe_customer_id': createCustomerResponse.customer.id });
          user.attributes['custom:stripe_customer_id'] = createCustomerResponse.customer.id;
        } else if (createCustomerResponse.error) {
          console.log(createCustomerResponse.error);
        }
      }
      Auth.updateUserAttributes(user,
        {
          name: (vatEntity.name || fields.name),
          phone_number: fields.telephone,
          address: (vatEntity.address || fields.address),
          ...(vatEntity.valid && !!vatEntity.vatNumber) && { 'custom:vat_number': `${vatEntity.countryCode}${vatEntity.vatNumber}` }
        })
        .then(response => console.log('[Auth.updateUserAttributes response]', response))
        .catch(error => console.error('[Auth.updateUserAttributes error]', error));
      /*
      todo:
      - generate invoice
      - create stripe subscription
      - trigger stripe payment method collection
      - confirm booking
        - show confirmation
        - send email confirmation
      */
      // associate name, address, telephone, vat number with stripe customer
      API.post('limelockers', `/${providerId}/${facilityId}/customer`,
        {
          body: {
            customerId: user.attributes['custom:stripe_customer_id'],
            ...((vatEntity.valid && vatEntity.name) || fields.name) && { name: (vatEntity.valid && !!vatEntity.name) ? vatEntity.name : fields.name },
            ...((vatEntity.valid && vatEntity.address) || fields.address) && { address: (vatEntity.valid && !!vatEntity.address) ? parseStripeAddress(vatEntity.countryCode, vatEntity.address) : { line1: fields.address } },
            ...(fields.telephone) && { phone: fields.telephone },
            ...(vatEntity.valid && !!vatEntity.vatNumber) && { taxId: { type: 'eu_vat', value: `${vatEntity.countryCode}${vatEntity.vatNumber}` } }
          }
        })
        .then(response => {
          console.log(`[API.post (/${providerId}/${facilityId}/customer) response]`, response);
          if (!response.status || !!response.error) {
            console.log(`[user]`, user);
          }
        })
        .catch(error => console.log(`[API.post (/${providerId}/${facilityId}/customer) error]`, error));

      // begin stripe subscription specific processing
      if (!stripe || !elements) {
        return;
      }
      const cardElement = elements.getElement(CardElement);
      const latestInvoicePaymentIntentStatus = localStorage.getItem(
        'latestInvoicePaymentIntentStatus'
      );
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });
      if (error) {
        console.log('[stripe.createPaymentMethod error]', error);
        switch (error.type) {
          case 'validation_error':
            if ('card' in validationSuccesses) {
              let {card, ...validationSuccessesWithCardSuccessesRemoved} = validationSuccesses;
              setValidationSuccesses(validationSuccessesWithCardSuccessesRemoved);
            }
            setValidationErrors({
              ...validationErrors,
              card: error.message
            });
            setIsSubmitting(false);
            break;
          default:
            break;
        }
      } else {
        console.log('[stripe.PaymentMethod created]', paymentMethod);
        setValidationSuccesses({ ...validationSuccesses, card: true });
        if ('card' in validationErrors) {
          let {card, ...validationErrorsWithCardErrorsRemoved} = validationErrors;
          setValidationErrors(validationErrorsWithCardErrorsRemoved);
        }
        const paymentMethodId = paymentMethod.id;
        const customerId = user.attributes['custom:stripe_customer_id'];
        const defaultTaxRates = [unitVariant.product.price[fields.paymentFrequency][currency].tax.VAT.id];
        const cancelAt = (fields.months > maxTenancyInMonths) ? undefined : moment(fields.to).format('X');
        const couponCode = (coupon.valid && !!fields.coupon) ? fields.coupon : undefined;
        const unit = unitVariant.available.units.find(_unit => _unit.unitId === fields.unitId);
        const reservation = {
          priceId: unitVariant.product.price[fields.paymentFrequency][currency].id,
          unitCategoryId: unit.unitCategoryId,
          unitVariantId: unit.unitVariantId,
          unitId: unit.unitId,
          from: moment(fields.from).format(),
          to: (fields.months > maxTenancyInMonths) ? undefined : moment(fields.to).format()
        };
        if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
          // Update the payment method and retry invoice payment
          const invoiceId = localStorage.getItem('latestInvoiceId');
          retryInvoiceWithNewPaymentMethod({ customerId, invoiceId, paymentMethodId });
        } else {
          // Create the subscription
          createSubscription({ cancelAt, customerId, paymentMethodId, reservation, defaultTaxRates, coupon: couponCode })
            .then(response => {
              setIsSubmitting(false);
              setRedirect('/bookings');
            })
            .catch(error => console.log('[createSubscription error]', error));
        }
      }
      // end stripe subscription specific processing
    })
    .catch(error => console.log('[Auth.currentAuthenticatedUser error]', error));
  }
  return (!!redirect)
    ?  <Redirect to={redirect} />
    : isLoading
      ? (
          <div className="text-center">
            <Spinner animation="border" size="lg" style={{marginTop: '250px', marginBottom: '250px'}} />
          </div>
        )
      : (
          (!!unitVariant)
            ? (
                <>
                  <Container id="book-form">
                    <Form onSubmit={handleSubmit} className="clearfix" noValidate>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Check
                              type="checkbox"
                              label="Do you require a VAT invoice?"
                              checked={showVatInput}
                              onChange={() => {setShowVatInput(!showVatInput)}} />
                          </Form.Group>
                          {
                            showVatInput
                              ? (
                                  <>
                                    <Form.Label>VAT Number:</Form.Label>
                                    <Form.Group className="form-inline">
                                      <Form.Control
                                        style={{ marginRight: '10px' }}
                                        id="countryCode"
                                        as="select"
                                        value={vatEntity.countryCode}
                                        onChange={handleVatNumberChange}>
                                        {
                                          ['AT', 'BE', 'BG', 'CY', 'CZ', 'DE', 'DK', 'EE', 'EL',  'ES', 'FI', 'FR', 'GB', 'HU', 'IE', 'IT', 'LT', 'LU', 'LV', 'MT', 'NL', 'PL', 'PT', 'SE', 'SI', 'SK'].map(countryCode => (
                                            <option key={countryCode}>
                                              {countryCode}
                                            </option>
                                          ))
                                        }
                                      </Form.Control>
                                      <Form.Control
                                        style={{ marginRight: '10px' }}
                                        id="vatNumber"
                                        type="text"
                                        placeholder="9999999"
                                        value={vatEntity.vatNumber}
                                        onChange={handleVatNumberChange}
                                        isValid={vatEntity.valid || !showVatInput}
                                        isInvalid={((!isVatNumberLookupRunning) && ('vat_number' in validationErrors))} />
                                      {
                                        isVatNumberLookupRunning
                                        ? <Spinner style={{ marginLeft: '10px' }} animation="border" size="sm" />
                                        : null
                                      }
                                      <Form.Text className="text-muted">
                                        VAT numbers are validated with the <a href="https://ec.europa.eu/taxation_customs/vies">European Commission VIES VAT number validator</a>. On verification, your invoice will be addressed to the company name and address registered with VIES.
                                      </Form.Text>
                                      <Form.Text style={{color: 'rgb(123, 179, 44)'}}>
                                        {validationSuccesses.vat_number}
                                      </Form.Text>
                                      <Form.Text className="text-danger">
                                        {validationErrors.vat_number}
                                      </Form.Text>
                                    </Form.Group>
                                  </>
                                )
                              : null
                          }
                          <Form.Group controlId="name">
                            <Form.Label>Name:</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="individual or company name"
                              value={(vatEntity.name || fields.name)}
                              isValid={validator.current.fieldValid('name')}
                              isInvalid={(('name' in touched) && (!validator.current.fieldValid('name')))}
                              onChange={e => { const {id, value} = e.target; handleFieldChange(f => ({...f, [id]: value})); setTouched({...touched, name: true}); validator.current.showMessageFor('name'); }}
                              disabled={((vatEntity.name !== '') && (vatEntity.valid))}
                            />
                            <Form.Text className="text-muted">
                              This name will appear on your invoice. Use your own name or if your company will claim a VAT rebate, check the VAT invoice box above and we'll use the company name associated with your EU registered, VAT number.
                            </Form.Text>
                            <Form.Text>
                              {
                                ('name' in touched)
                                  ? validator.current.message(
                                      'name',
                                      (vatEntity.name || fields.name),
                                      'required',
                                      {
                                        className: 'text-danger',
                                        messages: {
                                          required: 'Please enter a name.'
                                        }
                                      }
                                    )
                                  : null
                              }
                            </Form.Text>
                          </Form.Group>
                          <Form.Group controlId="telephone">
                            <Form.Label>Telephone:</Form.Label>
                            <Form.Control
                              type="phone"
                              placeholder="+359 555 123 456"
                              value={fields.telephone}
                              isValid={validator.current.fieldValid('telephone')}
                              isInvalid={(('telephone' in touched) && (!validator.current.fieldValid('telephone')))}
                              onChange={e => { const {id, value} = e.target; handleFieldChange(f => ({...f, [id]: value})); setTouched({...touched, telephone: true}); validator.current.showMessageFor('telephone'); }}
                            />
                            <Form.Text className="text-muted">
                              This number will receive communications about your self-storage and related billing only.
                            </Form.Text>
                            <Form.Text>
                              {
                                ('telephone' in touched)
                                  ? validator.current.message(
                                      'telephone',
                                      fields.telephone,
                                      'required|phone',
                                      {
                                        className: 'text-danger',
                                        messages: {
                                          required: 'Please enter a telephone number.'
                                        }
                                      }
                                    )
                                  : null
                              }
                            </Form.Text>
                          </Form.Group>
                          <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={(vatEntity.address || fields.address)}
                              isValid={((vatEntity.valid || !showVatInput) && !!fields.address)}
                              onChange={e => { const {id, value} = e.target; handleFieldChange(f => ({...f, [id]: value})); }}
                              disabled={((vatEntity.address !== '') && (vatEntity.valid))}
                            />
                            <Form.Text className="text-muted">
                              This address will appear on invoices only. We do not send postal mail of any kind.
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>What type of locker or space do you require?</Form.Label>
                            <ul>
                              {
                                Object.keys(catalog).map(category => (
                                  <li
                                    key={category}
                                    className={(fields.unit.startsWith(category)) ? '' : 'text-muted'}>
                                    <span>{category}:</span>
                                    {
                                      Object.keys(catalog[category]).map(variant => (
                                        <Form.Check key={variant}>
                                          <Form.Check.Input
                                            style={{cursor: 'pointer'}}
                                            type="radio"
                                            disabled={(catalog[category][variant].available.units.length === 0)}
                                            checked={fields.unit === `${category}/${variant}`}
                                            onChange={() => {handleCardSelect(category, variant);}} />
                                          <Form.Check.Label
                                            style={{ cursor: (catalog[category][variant].available.units.length === 0) ? 'not-allowed' : 'pointer' }}
                                            title={(catalog[category][variant].available.units.length === 0) ? 'not available on the selected dates' : null }
                                            className={(fields.unit === `${category}/${variant}`) ? 'active' : (catalog[category][variant].available.units.length === 0) ? 'text-muted' : 'text-dark'}
                                            onClick={() => {handleCardSelect(category, variant);}}>
                                            {`${variant} - ${(catalog[category][variant].available.units.length === 0) ? 'sold out' : catalog[category][variant].available.units.length + ' available'}`}
                                          </Form.Check.Label>
                                        </Form.Check>
                                      ))
                                    }
                                  </li>
                                ))
                              }
                            </ul>
                            {
                              (!!unitVariant.dimensions && !!unitVariant.dimensions.locker)
                                ? (
                                    <Form.Text className="text-muted">
                                      {`width: ${unitVariant.dimensions.locker.width / 100}m`}
                                      &nbsp;
                                      &times;
                                      &nbsp;
                                      {`depth: ${unitVariant.dimensions.locker.depth / 100}m`}
                                      &nbsp;
                                      &times;
                                      &nbsp;
                                      {`height: ${unitVariant.dimensions.locker.height / 100}m`}
                                    </Form.Text>
                                  )
                                : null
                            }
                          </Form.Group>
                          <Form.Group>
                            <Form.Label>
                              Choose your { fields.unit.includes('enclosed lockers') ? 'locker' : fields.unit.includes('bicycle') ? 'bike rack space' : 'motorcycle parking bay' }:
                            </Form.Label>
                            <br />
                            {
                              unitVariant.available.units
                                // hide units held for others
                                .filter(u => (!u.held || u.held.for === user.attributes.email))
                                // sort units
                                .sort(
                                  (a, b) => 
                                    (!!a.held || !!b.held)
                                      // show units held for user first
                                      ? (!!a.held && !!b.held) ? 0 : (!!a.held) ? -1 : 1
                                      // otherwise sort by name
                                      : (a.name === b.name) ? 0 : (a.name > b.name) ? 1 : -1
                                )
                                // show only the first 5 available units
                                .slice(0, 5)
                                .map(unit => (
                                  <Form.Check
                                    inline
                                    key={unit.unitId}>
                                    <Form.Check.Input
                                      style={{cursor: 'pointer'}}
                                      type="radio"
                                      checked={fields.unitId === unit.unitId}
                                      onChange={() => { handleFieldChange(f => ({...f, unitId: unit.unitId})); }} />
                                    <Form.Check.Label
                                      style={{ cursor: 'pointer' }}
                                      className={(fields.unitId === unit.unitId) ? 'active' : 'text-muted'}
                                      onClick={() => { handleFieldChange(f => ({...f, unitId: unit.unitId})); }}>
                                      {`${unit.name}${(!!unit.held && unit.held.for === user.attributes.email) ? ' - reserved for you' : ''}`}
                                    </Form.Check.Label>
                                  </Form.Check>
                                ))
                            }
                            <Form.Text className="text-muted">
                              {(!!fields.unitId) ? unitVariant.available.units.find(unit => unit.unitId === fields.unitId).description : null}
                            </Form.Text>
                          </Form.Group>
                          <Form.Group>
                            <Row>
                              <Col>
                                <Form.Label>Start Date:</Form.Label>
                                <br />
                                <SingleDatePicker
                                  displayFormat="MMM DD YYYY"
                                  date={moment(fields.from)}
                                  onDateChange={e => { handleFieldChange(f => ({ ...f, from: e.toISOString(), to: e.add(fields.months, 'M').toISOString() }));}}
                                  focused={focusedDate}
                                  onFocusChange={({ focused }) => setFocusedDate(focused)}
                                  id="from"
                                />
                              </Col>
                              <Col>
                                {
                                  (fields.months <= maxTenancyInMonths)
                                    ? (
                                        <>
                                          <Form.Label>{(fields.paymentFrequency === 'year') ? 'Years' : 'Months'} Required:</Form.Label>
                                          <br />
                                          <Form.Control
                                            id="months"
                                            type="number"
                                            min={1}
                                            max={(fields.paymentFrequency === 'year') ? Math.floor(maxTenancyInMonths/12) : maxTenancyInMonths}
                                            value={(fields.paymentFrequency === 'year') ? Math.floor(fields.months/12) : fields.months}
                                            onChange={
                                              e => {
                                                const months = (fields.paymentFrequency === 'year') ? (Math.max(Math.min(parseInt(e.target.value), Math.floor(maxTenancyInMonths / 12)), 1) * 12) : Math.max(Math.min(parseInt(e.target.value), maxTenancyInMonths), 1);
                                                handleFieldChange(
                                                  f => (
                                                    {
                                                      ...f,
                                                      months,
                                                      to: moment(fields.from).add(months, 'M').toISOString()
                                                    }
                                                  )
                                                );
                                              }
                                            }
                                          />
                                        </>
                                    )
                                  : null
                                }
                                {
                                  (fields.months > maxTenancyInMonths)
                                    ? (
                                        <Form.Label>End Date:</Form.Label>
                                      )
                                    : null
                                }
                                {
                                  <Form.Check
                                    type="checkbox"
                                    label="Until Cancelled"
                                    checked={(fields.months > maxTenancyInMonths)}
                                    onChange={
                                      e => {
                                        handleFieldChange(
                                          f => {
                                            const months = (f.months > maxTenancyInMonths)
                                              ? 12 //(f.paymentFrequency === 'year') ? (Math.max(Math.min(f.months, Math.floor(maxTenancyInMonths / 12)), 1) * 12) : Math.max(Math.min(f.months, maxTenancyInMonths), 1)
                                              : (maxTenancyInMonths + 1);
                                            return (
                                              {
                                                ...f,
                                                months,
                                                to: moment(f.from).add(months, 'M').toISOString()
                                              }
                                            );
                                          }
                                        );
                                      }
                                    } />
                                }
                              </Col>
                              <Col>
                                {
                                  (fields.months <= maxTenancyInMonths)
                                    ? (
                                        <>
                                          <Form.Label>
                                            End Date:
                                          </Form.Label>
                                          <br />
                                          {Intl.DateTimeFormat(undefined, { year: 'numeric', month: 'short', day: 'numeric' }).format(new Date(fields.to))}
                                        </>
                                      )
                                    : null
                                }
                                {
                                  (fields.paymentFrequency === 'year')
                                    ? (
                                        <Form.Text className="active">
                                          {
                                            (fields.months <= maxTenancyInMonths)
                                              ? `${Math.floor(fields.months / 12)} month${(Math.floor(fields.months / 12) > 1) ? 's' : ''} free!`
                                              : 'One free month every year!'
                                          }
                                        </Form.Text>
                                      )
                                    : null
                                }
                              </Col>
                            </Row>
                            <Form.Text className="text-muted">
                              Subject to availability, you may amend your booking to change to an earlier or later end date at any time.
                            </Form.Text>
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Form.Group controlId="coupon">
                            <Form.Label>Discount code:</Form.Label>
                            <Form.Control
                              type="text"
                              value={fields.coupon}
                              isValid={!!coupon.valid}
                              isInvalid={!!coupon.invalid}
                              disabled={!!coupon.valid}
                              onChange={
                                e => {
                                  const {id, value} = e.target;
                                  handleFieldChange(f => ({...f, [id]: value}));
                                }
                              }
                            />
                            <Form.Text className={(!!coupon.message) ? ((!!coupon.invalid) ? 'text-danger' : 'text-success') : 'text-muted'}>
                              {
                                (!!coupon.message)
                                  ? coupon.message
                                  : 'If you have a discount code, enter it here.'
                              }
                            </Form.Text>
                          </Form.Group>
                          <hr />
                          <Table size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">
                                  description
                                </th>
                                <th className="text-muted text-right">
                                  qty
                                </th>
                                <th className="text-muted text-right">
                                  unit price
                                </th>
                                <th className="text-muted text-right">
                                  amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td colSpan="4" className="text-muted">
                                  {moment(fields.from).format('MMM D, YYYY')}
                                  &nbsp;-&nbsp;
                                  {moment(fields.from).add(1, (fields.paymentFrequency === 'year') ? 'Y' : 'M').format('MMM D, YYYY')}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  {fields.unit}
                                </td>
                                <td className="text-right">
                                  1
                                </td>
                                <td className="text-right text-muted">
                                  {currencySymbol[currency]}
                                  {
                                    (fields.paymentFrequency === 'year')
                                      ? Number.parseFloat(price.amount.base / 11 * 12).toFixed(2)
                                      : Number.parseFloat(price.amount.base).toFixed(2)
                                  }
                                </td>
                                <td className="text-right">
                                  {currencySymbol[currency]}
                                  {
                                    (fields.paymentFrequency === 'year')
                                      ? Number.parseFloat(price.amount.base / 11 * 12).toFixed(2)
                                      : Number.parseFloat(price.amount.base).toFixed(2)
                                  }
                                </td>
                              </tr>
                              {
                                (fields.paymentFrequency === 'year')
                                  ? (
                                      <tr className="active">
                                        <td colSpan="3" className="text-right">
                                          discount: <em>annual payment</em> (1 month free)
                                        </td>
                                        <td className="text-right">
                                          - {currencySymbol[currency]}
                                          {Number.parseFloat(price.amount.base / 11).toFixed(2)}
                                        </td>
                                      </tr>
                                    )
                                  : null
                              }
                              {
                                (price.discount.amount)
                                  ? (
                                      <tr className="active">
                                        <td colSpan="3" className="text-right">
                                          discount: <em>{coupon.name}</em> ({price.discount.percent}%)
                                        </td>
                                        <td className="text-right">
                                          -{currencySymbol[currency]}
                                          {Number.parseFloat(price.discount.amount).toFixed(2)}
                                        </td>
                                      </tr>
                                    )
                                  : null
                              }
                              <tr>
                                <td colSpan="3" className="text-right">
                                  subtotal
                                </td>
                                <td className="text-right">
                                  {currencySymbol[currency]}
                                  {Number.parseFloat(price.amount.subtotal).toFixed(2)}
                                </td>
                              </tr>
                              {
                                (price.vat.amount)
                                  ? (
                                      <tr>
                                        <td colSpan="3" className="text-muted text-right">
                                          VAT - Bulgaria ({price.vat.percent}%)
                                        </td>
                                        <td className="text-right">
                                          {currencySymbol[currency]}
                                          {Number.parseFloat(price.vat.amount).toFixed(2)}
                                        </td>
                                      </tr>
                                    )
                                  : null
                              }
                              <tr>
                                <td colSpan="3" className="text-right">
                                  <strong>
                                    {
                                      ((fields.months/((fields.paymentFrequency === 'year') ? 12 : 1)) > 1)
                                        ? (fields.paymentFrequency === 'year') ? 'yearly' : 'monthly'
                                        : null
                                    } total
                                  </strong>
                                  {
                                    ((fields.months/((fields.paymentFrequency === 'year') ? 12 : 1)) > 1)
                                      ? (
                                          <span className="text-muted">
                                            &nbsp;(each {fields.paymentFrequency}{
                                              (fields.months <= maxTenancyInMonths)
                                                ? <>, for {Math.floor(fields.months/((fields.paymentFrequency === 'year') ? 12 : 1))} {fields.paymentFrequency}s</>
                                                : null
                                            })
                                          </span>
                                        )
                                      : null
                                  }
                                </td>
                                <td className="text-right">
                                  <strong>
                                    {currencySymbol[currency]}
                                    {Number.parseFloat(price.amount.total).toFixed(2)}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </Col>
                        <Col>
                          <Form.Group>
                            <Form.Label>Please tell us how often to collect payments.</Form.Label>
                            <Form.Check>
                              <Form.Check.Input
                                style={{cursor: 'pointer'}}
                                type="radio"
                                disabled={false}
                                checked={fields.paymentFrequency !== 'year'}
                                onChange={e => { handleFieldChange(f => ({ ...f, paymentFrequency: 'month' })); }} />
                              <Form.Check.Label
                                style={{ cursor: (unitVariant.available.units.length === 0) ? 'not-allowed' : 'pointer' }}
                                className={(fields.paymentFrequency !== 'year') ? 'active' : 'text-muted'}
                                onClick={e => { handleFieldChange(f => ({ ...f, paymentFrequency: 'month' })); }}>
                                monthly payment: <span>
                                {
                                  (coupon.valid)
                                    ? (
                                        <>
                                          <span className="text-muted" style={{textDecoration: 'line-through', color: '#000000'}}>
                                            лв{Number.parseFloat((priceCalculator(unitVariant, 'month', currency, coupon)).amount.baseWithVat).toFixed(2)}
                                          </span>
                                          &nbsp;
                                          <span>
                                            лв{Number.parseFloat((priceCalculator(unitVariant, 'month', currency, coupon)).amount.total).toFixed(2)}
                                          </span>
                                        </>
                                      )
                                    : (
                                        <span>
                                          лв{Number.parseFloat((priceCalculator(unitVariant, 'month', currency, coupon)).amount.total).toFixed(2)}
                                        </span>
                                      )
                                } bgn</span> per month
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check>
                              <Form.Check.Input
                                style={{cursor: 'pointer'}}
                                type="radio"
                                disabled={false}
                                checked={fields.paymentFrequency === 'year'}
                                onChange={e => { handleFieldChange(f => ({ ...f, paymentFrequency: 'year' })); }} />
                              <Form.Check.Label
                                style={{ cursor: (unitVariant.available.units.length === 0) ? 'not-allowed' : 'pointer' }}
                                className={(fields.paymentFrequency === 'year') ? 'active' : 'text-muted'}
                                onClick={e => { handleFieldChange(f => ({ ...f, paymentFrequency: 'year' })); }}>
                                annual payment: <span>
                                {
                                  (coupon.valid)
                                    ? (
                                        <>
                                          <span className="text-muted" style={{textDecoration: 'line-through', color: '#000000'}}>
                                            лв{Number.parseFloat((priceCalculator(unitVariant, 'year', currency, coupon)).amount.baseWithVat).toFixed(2)}
                                          </span>
                                          &nbsp;
                                          <span>
                                            лв{Number.parseFloat((priceCalculator(unitVariant, 'year', currency, coupon)).amount.total).toFixed(2)}
                                          </span>
                                        </>
                                      )
                                    : (
                                        <span>
                                          лв{Number.parseFloat((priceCalculator(unitVariant, 'year', currency, coupon)).amount.total).toFixed(2)}
                                        </span>
                                      )
                                } bgn</span> per year
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Text className="text-muted">
                              Customers choosing the annual payment option will receive 12 months for the price of 11.
                              <br />
                              - A saving of <span>лв{Number.parseFloat((priceCalculator(unitVariant, 'month', currency, coupon)).amount.total).toFixed(2)} bgn</span> per year.
                            </Form.Text>
                          </Form.Group>
                          <hr />
                          <Form.Group>
                            <Form.Label>
                              How would you like to make payment?
                            </Form.Label>
                            <br />
                            {
                              [
                                {
                                  id: 'card',
                                  title: 'credit, debit or prepaid card'
                                },
                                {
                                  id: 'bank transfer',
                                  title: 'bank transfer or transferwise'
                                },
                                {
                                  id: 'cash',
                                  title: 'cash'
                                }/*,
                                {
                                  id: 'cryptocurrency',
                                  title: 'cryptocurrency'
                                }*/
                              ].map(paymentMethod => (
                                  <Form.Check
                                    inline
                                    key={paymentMethod.id}>
                                    <Form.Check.Input
                                      style={{cursor: 'pointer'}}
                                      type="radio"
                                      checked={fields.paymentMethod === paymentMethod.id}
                                      onChange={() => { handleFieldChange(f => ({...f, paymentMethod: paymentMethod.id})); }} />
                                    <Form.Check.Label
                                      style={{ cursor: 'pointer' }}
                                      className={(fields.paymentMethod === paymentMethod.id) ? 'active' : 'text-muted'}
                                      onClick={() => { handleFieldChange(f => ({...f, paymentMethod: paymentMethod.id})); }}>
                                      {paymentMethod.id}
                                    </Form.Check.Label>
                                  </Form.Check>
                                ))
                            }
                          </Form.Group>
                          {
                            (fields.paymentMethod === 'card')
                              ? (
                                  <Form.Group>
                                    <Form.Label>Payment Card:</Form.Label>
                                    <CardElement
                                      id="stripe-payment-method"
                                      onChange={() => {
                                        if ('card' in validationErrors) {
                                          let {card, ...validationErrorsWithCardErrorsRemoved} = validationErrors;
                                          setValidationErrors(validationErrorsWithCardErrorsRemoved);
                                        }
                                      }}
                                      options={{
                                        style: {
                                          base: {
                                            color: 'rgb(48, 11, 11)',
                                            fontFamily: 'Roboto, sans-serif',
                                            fontSmoothing: 'antialiased',
                                            fontSize: "16px",
                                            '::placeholder': {
                                              color: '#aab7c4',
                                            },
                                            iconColor: '#ff3399',
                                          },
                                          invalid: {
                                            color: "#fa755a",
                                            iconColor: "#fa755a",
                                          },
                                        },
                                      }} />
                                    <Form.Text className="text-danger">
                                      {validationErrors.card}
                                    </Form.Text>
                                    <Form.Text className="text-muted">

                                      You have requested use of self-storage space {
                                        (fields.months > maxTenancyInMonths)
                                          ? (
                                              <>
                                                until you let us know it is no longer required.
                                              </>
                                            )
                                          : (
                                              <>
                                                for a period of {(fields.months/((fields.paymentFrequency === 'year') ? 12 : 1))} {(fields.paymentFrequency === 'year') ? 'years' : 'months'}.
                                              </>
                                            )
                                      }<br />
                                      {
                                        ((fields.months/((fields.paymentFrequency === 'year') ? 12 : 1)) > 1)
                                          ? (
                                              <>
                                                Subscription payments will be debited from your payment card today and will repeat each {(fields.paymentFrequency === 'year') ? 'year' : 'month'}.
                                              </>
                                            )
                                          : (
                                              <>
                                                One payment will be debited from your payment card, when you submit this form.
                                              </>
                                            )
                                      }
                                    </Form.Text>
                                  </Form.Group>
                                )
                              : (['bank transfer', 'cash'].includes(fields.paymentMethod) && !!fields.telephone && !!fields.from && !!fields.unitId)
                                ? (
                                    <>
                                      {
                                        (fields.paymentMethod === 'cash')
                                          ? (
                                              <p>Please note that we are unable to accept cash payments at the storage facility, however cash payments can be made at any DSK branch in Bulgaria, using the following account details:</p>
                                            )
                                          : (
                                              <p>Please use the following bank transfer details:</p>
                                            )
                                      }
                                      <dl>
                                        <dt>Account Name</dt>
                                        <dd>
                                          Bears Lairs EOOD / Беърс Леърс ЕООД
                                          {
                                            (fields.paymentMethod === 'bank transfer')
                                              ? (
                                                  <>
                                                    <br />
                                                    <span className="text-muted">
                                                      Please use whichever alphabet (Latin / Cyrillic) is accepted by your bank. It is not necessary to use both.
                                                    </span>
                                                  </>
                                                )
                                              : null
                                            }
                                        </dd>
                                        <dt>IBAN</dt>
                                        <dd>
                                          <span style={{marginRight: '0.3em'}}>
                                            BG57
                                          </span>
                                          <span style={{marginRight: '0.3em'}}>
                                            STSA
                                          </span>
                                          <span style={{marginRight: '0.3em'}}>
                                            9300
                                          </span>
                                          <span style={{marginRight: '0.3em'}}>
                                            1528
                                          </span>
                                          <span style={{marginRight: '0.3em'}}>
                                            6047
                                          </span>
                                          <span>
                                            01
                                          </span>
                                        </dd>
                                        <dt>Currency</dt>
                                        <dd>BGN - Bulgarian Lev</dd>
                                        <dt>{(fields.paymentMethod === 'cash') ? 'Deposit' : 'Transfer'} Reference</dt>
                                        <dd>
                                          <span style={{marginRight: '0.3em'}}>
                                            BLB{unitVariant.available.units.find(_unit => _unit.unitId === fields.unitId).name}
                                          </span>
                                          <span style={{marginRight: '0.3em'}}>
                                            {fields.telephone.slice(-5)}
                                          </span>
                                          <span>
                                            {moment(fields.from).format('YYYY').slice(-1)}{moment(fields.from).format('MMDD')}
                                          </span>
                                          <br />
                                          <span className="text-muted">
                                            Please include the exact reference above in your {(fields.paymentMethod === 'cash') ? 'deposit' : 'transfer'} (spaces are optional). This reference allows us to apply a received payment to a specific reservation.
                                          </span>
                                        </dd>
                                      </dl>
                                    </>
                                  )
                                : (
                                    (!fields.telephone)
                                      ? (
                                          <p>
                                            Please add your telephone number above.
                                          </p>
                                        )
                                      : null
                                    (!fields.from)
                                      ? (
                                          <p>
                                            Please select a start date.
                                          </p>
                                        )
                                      : null
                                    (!fields.unitId)
                                      ? (
                                          <p>
                                            Please select a storage unit.
                                          </p>
                                        )
                                      : null
                                  )
                          }
                          <Row>
                            <Col>
                              <Form.Group>
                                <Form.Check
                                  type="checkbox"
                                  label={(
                                    <span>I have read and accepted the <a href="/terms" target="_blank">terms and conditions</a>.</span>
                                    )}
                                  checked={fields.termsAccepted}
                                  onChange={() => {handleFieldChange(f => ({...f, termsAccepted: !f.termsAccepted}))}} />
                              </Form.Group>
                            </Col>
                            <Col md="auto">
                              <LoaderButton
                                type="submit"
                                className="float-right"
                                isLoading={isSubmitting}
                                disabled={!validateForm()}>
                                book
                              </LoaderButton>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                  </Container>
                  <Container id="book-units">
                    <Tabs activeKey={fields.unit.split('/')[0]} onSelect={(category) => handleTabSelect(category)}>
                      {
                        Object.keys(catalog).map(category => (
                          <Tab key={category} eventKey={category} title={category}>
                            <CardDeck>
                            {
                              Object.keys(catalog[category]).map(variant => (
                                <Card
                                  key={variant}
                                  className={(fields.unit === `${category}/${variant}`) ? 'active' : 'text-muted'}
                                  onClick={() => handleCardSelect(category,  variant)}>
                                  <Card.Header
                                    style={{cursor: 'pointer'}}
                                    as="div">
                                    <span className={`float-right availability-${(catalog[category][variant].available.units.length < 1) ? 'zero' : (catalog[category][variant].available.units.length < 3) ? 'low' : 'high'}`}>
                                      available: <strong>{catalog[category][variant].available.units.length}</strong>
                                    </span>
                                  </Card.Header>
                                  <Card.Img
                                    variant="top"
                                    src={catalog[category][variant].image.url}
                                    className="mx-auto"
                                    style={{cursor: 'pointer', width: '100%', opacity: (fields.unit === `${category}/${variant}`) ? 1 : 0.2}} />
                                  <Card.Body>
                                    <Card.Title>
                                      {variant}
                                    </Card.Title>
                                    <Card.Text>
                                      {catalog[category][variant].description}
                                    </Card.Text>
                                    <Card.Text as="div">
                                      <strong>unit price:</strong>
                                      <ul>
                                        <li className="price active">
                                          <span>лв{Number.parseFloat(priceCalculator(catalog[category][variant], 'year', currency, coupon).amount.total).toFixed(2)} bgn</span> yearly
                                        </li>
                                        <li className="price active">
                                          <span>лв{Number.parseFloat(priceCalculator(catalog[category][variant], 'month', currency, coupon).amount.total).toFixed(2)} bgn</span> monthly
                                        </li>
                                      </ul>
                                    </Card.Text>
                                    {
                                      catalog[category][variant].dimensions &&
                                      <Card.Text as="div">
                                        <strong>locker size</strong>
                                        <ul>
                                          {
                                            Object.keys(catalog[category][variant].dimensions.locker).map(prop => (
                                              <li key={prop}>{prop}: {catalog[category][variant].dimensions.locker[prop] / 100} meter</li>
                                            ))
                                          }
                                          <li>
                                            area: {
                                              ((catalog[category][variant].dimensions.locker.width / 100) * (catalog[category][variant].dimensions.locker.depth / 100)).toFixed(1)
                                            } m<sup>2</sup>
                                          </li>
                                          <li>
                                            volume: {
                                              ((catalog[category][variant].dimensions.locker.width / 100) * (catalog[category][variant].dimensions.locker.height / 100) * (catalog[category][variant].dimensions.locker.depth / 100)).toFixed(1)
                                            } m<sup>3</sup>
                                          </li>
                                        </ul>
                                        <strong>door size</strong>
                                        <ul>
                                          {
                                            Object.keys(catalog[category][variant].dimensions.door).map(prop => (
                                              <li key={prop}>{prop}: {catalog[category][variant].dimensions.door[prop]/100} meter</li>
                                            ))
                                          }
                                        </ul>
                                      </Card.Text>
                                    }
                                  </Card.Body>
                                </Card>
                              ))
                            }
                            </CardDeck>
                          </Tab>
                        ))
                      }
                    </Tabs>
                  </Container>
                </>
              )
            : null
        );
}
