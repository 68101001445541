import React, { useEffect, useState } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { API } from 'aws-amplify';
import { locale } from '../contexts/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from 'react-bootstrap/Spinner';

const providerId = 'e986bca0-ff41-11ea-8196-af25e422bd08';
const facilityId = 'ff1694c0-ffa8-11ea-bfbc-4f270cb046f3';

export default function BookingList() {
  const [isLoading, setIsLoading] = useState(true);
  const [account, setAccount] = useState(undefined);
  const [activeReservation, setActiveReservation] = useState(`r0`);

  useEffect(() => {
    if (!account) {
      API.get('limelockers', `/${providerId}/${facilityId}/account`)
        .then(response => {
          if (response.status && response.account) {
            setAccount(response.account);
            console.log(response.account);
          } else {
            setIsLoading(false);
          }
        })
        .catch(console.error)
    }
  });
  
  return (
    <Container id="bookings">
      <h2>Bookings</h2>
      {
        (!!account && !!account.reservations && !!account.reservations.length)
          ? (
              <Accordion activeKey={activeReservation}>
                {
                  account.reservations.map((reservation, rI) => (
                    <Card key={`r${rI}`}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={rI}
                        onClick={() => {setActiveReservation(`r${rI}`)}}>
                        Unit {reservation.unit.name}
                        <span className="float-right">
                          {moment(reservation.from).format('MMM YYYY')} ~ {
                            (!!reservation.to)
                              ? moment(reservation.to).format('MMM YYYY')
                              : 'Until cancelled'
                          }
                        </span>
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={`r${rI}`}>
                        <Card.Body>
                          <a className="float-right" href={reservation.subscription.latest_invoice.invoice_pdf}>
                            <FontAwesomeIcon icon={['far', 'file-pdf']} color="#ff3399" />
                          </a>
                          <a className="float-right" href={reservation.subscription.latest_invoice.hosted_invoice_url}
                            style={{marginRight: '0.5em'}}
                            target="_blank"
                            rel="noopener noreferrer">
                            invoice
                          </a>
                          {
                            (
                              !!reservation.subscription.latest_invoice.payment_intent.charges.data
                              && !!reservation.subscription.latest_invoice.payment_intent.charges.data.length
                              && !!reservation.subscription.latest_invoice.payment_intent.charges.data[0].receipt_url)
                              ? (
                                  <a className="float-right" href={reservation.subscription.latest_invoice.payment_intent.charges.data[0].receipt_url}
                                    style={{marginRight: '0.5em'}}
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    receipt
                                  </a>
                                )
                              : null
                          } 
                          <Card.Title>
                            Latest invoice
                          </Card.Title>
                          <Table size="sm">
                            <thead>
                              <tr>
                                <th className="text-muted">
                                  description
                                </th>
                                <th className="text-muted text-right">
                                  quantity
                                </th>
                                <th className="text-muted text-right">
                                  unit price
                                </th>
                                <th className="text-muted text-right">
                                  amount
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                reservation.subscription.latest_invoice.lines.data.map(line => {
                                  //const rate = reservation.subscription.latest_invoice.default_tax_rates.find(_rate => _rate.id === tax.tax_rate);
                                  return (
                                    <tr key={line.subscription_item}>
                                      <td className="text-muted">
                                        {moment(line.period.start * 1000).format('MMM-DD-YY')} ~ {moment(line.period.end * 1000).format('MMM-DD-YY')}
                                        &nbsp;-&nbsp;
                                        {line.price.nickname.replace('corona ', '')}
                                      </td>
                                      <td className="text-muted text-right">
                                        {line.quantity}
                                      </td>
                                      <td className="text-muted text-right">
                                        {Number.parseFloat(line.price.unit_amount / 100).toFixed(2)}
                                      </td>
                                      <td className="text-right">
                                        {locale['en-bg'].currency.symbol}
                                        {Number.parseFloat(line.amount / 100).toFixed(2)} {locale['en-bg'].currency.code}
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                              {
                                (!!reservation.subscription.latest_invoice.discount && !!reservation.subscription.latest_invoice.discount.coupon && !!reservation.subscription.latest_invoice.discount.coupon.percent_off)
                                  ? (
                                      <tr className="active">
                                        <td colSpan="3" className="text-right">
                                          discount: {reservation.subscription.latest_invoice.discount.coupon.name} ({reservation.subscription.latest_invoice.discount.coupon.percent_off}%)
                                        </td>
                                        <td className="text-right">
                                          -{locale['en-bg'].currency.symbol}
                                          {Number.parseFloat((reservation.subscription.latest_invoice.subtotal * (reservation.subscription.latest_invoice.discount.coupon.percent_off / 100)) / 100).toFixed(2)} {locale['en-bg'].currency.code}
                                        </td>
                                      </tr>
                                    )
                                  : null
                              }
                              <tr>
                                <td colSpan="3" className="text-right">
                                  subtotal
                                </td>
                                <td className="text-right">
                                  {locale['en-bg'].currency.symbol}
                                  {
                                    Number.parseFloat((
                                      (!!reservation.subscription.latest_invoice.discount && !!reservation.subscription.latest_invoice.discount.coupon && !!reservation.subscription.latest_invoice.discount.coupon.percent_off)
                                        // subtotal - discount
                                        ? (reservation.subscription.latest_invoice.subtotal * ((100 - reservation.subscription.latest_invoice.discount.coupon.percent_off) / 100))
                                        // subtotal
                                        : (reservation.subscription.latest_invoice.subtotal)
                                    ) / 100).toFixed(2)
                                  } {locale['en-bg'].currency.code}
                                </td>
                              </tr>
                              {
                                reservation.subscription.latest_invoice.total_tax_amounts.map(tax => {
                                  const rate = reservation.subscription.latest_invoice.default_tax_rates.find(_rate => _rate.id === tax.tax_rate);
                                  return (
                                    <tr key={tax.tax_rate}>
                                      <td colSpan="3" className="text-muted text-right">
                                        {rate.display_name} - {rate.jurisdiction} ({rate.percentage}%)
                                      </td>
                                      <td className="text-right">
                                        {locale['en-bg'].currency.symbol}
                                        {Number.parseFloat(tax.amount / 100).toFixed(2)} {locale['en-bg'].currency.code}
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                              <tr>
                                <td colSpan="3" className="text-right">
                                  total
                                </td>
                                <td className="text-right">
                                  {locale['en-bg'].currency.symbol}
                                  {Number.parseFloat(reservation.subscription.latest_invoice.total / 100).toFixed(2)} {locale['en-bg'].currency.code}
                                </td>
                              </tr>
                              <tr>
                                <td colSpan="3" className="text-right">
                                  {
                                    (reservation.subscription.latest_invoice.paid)
                                      ? (
                                          <span className="success">
                                            Payment received {moment(reservation.subscription.latest_invoice.status_transitions.paid_at * 1000).format('HH:mm DD/MM/YYYY')}, with thanks!
                                          </span>
                                        )
                                      : (
                                          <span className="active">
                                            Payment due
                                          </span>
                                        )
                                  }
                                </td>
                                <td className="text-right">
                                  <span className={(reservation.subscription.latest_invoice.paid) ? 'success' : 'active'}>
                                    {locale['en-bg'].currency.symbol}
                                    {Number.parseFloat(reservation.subscription.latest_invoice.amount_paid / 100).toFixed(2)} {locale['en-bg'].currency.code}
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <pre>{/*JSON.stringify(reservation.subscription, null, 2)*/}</pre>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  ))
                }
              </Accordion>
            )
          : (
              <div className="text-center">
                {
                  isLoading
                    ? <Spinner animation="border" size="lg" />
                    : (
                        <div>
                          <p>no bookings found</p>
                          <LinkContainer to="/book">
                            <Button className="book-now btn">book now</Button>
                          </LinkContainer>
                        </div>
                      )
                }
              </div>
            )
      }
    </Container>
  );
}
