import React, { useState, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import GoogleMapReact from 'google-map-react';
import Thermometer from 'react-thermometer-component';

export default function Home() {
  const lairImages = [
    '/img/lair-baby.png',
    '/img/lair-mama.png',
    '/img/lair-papa.png'
  ];
  const lairIcons = [
    '/img/lair-baby-icon.png',
    '/img/lair-mama-icon.png',
    '/img/lair-papa-icon.png'
  ];
  const bikeImages = [
    '/img/bike-bay-small.png',
    '/img/bike-bay-medium.png',
    '/img/bike-bay-large.png'
  ];
  const bikeIcons = [
    '/img/bike-rack-icon.png',
    '/img/bike-small-icon.png',
    '/img/bike-large-icon.png'
  ];

  const CopyApi = 'https://raw.githubusercontent.com/bearslairs/bearslairs-data/master/copy';
  const UbiBotApi = 'https://api.ubibot.io/channels/13604?api_key=609210eb2306427a88d662d48ddb578d';
  const [content, setContent] = useState({ language: 'en', copy: { carousel: [], blurbs: [], cards: []}});
  const [climate, setClimate] = useState({ temperature: { created_at: Date.now(), value: 0 }, humidity: { created_at: Date.now(), value: 0 }});

  useEffect(() => {
    fetch(CopyApi + '/' + content.language + '/home.json')
      .then(responseCopyApi => responseCopyApi.json())
      .then((copy) => {
        setContent({
          language: content.language,
          copy: copy
        });
      })
      .catch(console.log);
  }, [content]);
  useEffect(() => {
    fetch(UbiBotApi)
      .then(responseUbiBotApi => responseUbiBotApi.json())
      .then((container) => {
        if (container.result === 'success') {
          let lastValues = JSON.parse(container.channel.last_values);
          setClimate({
            temperature: lastValues.field1,
            humidity: lastValues.field2
          });
        }
      })
      .catch(console.log);
  }, [climate]);

  return (
    <>
      <Container id="header" fluid>
        <h2>
          secure self storage in bansko<br />
          with access at your convenience
        </h2>
      </Container>
      <Container id="environment">
        <h3>
          real-time environment monitoring
        </h3>
        <p>
          we monitor temperature, humidity and light within the facility and publish those readings at
          &nbsp;
          <a href="https://space.ubibot.io/space/user/device/channel-id/13604">
            ubibot.io/bearslairs-bansko
          </a>,
          <br />
          so our customers can stay informed of the environmetal conditions of their self-storage.
          The latest readings taken were:
        </p>
        <Row className="justify-content-xl-center justify-content-md-center">
          <Col xl="auto" md="auto">
            <Thermometer
              theme="dark"
              value={(Math.round(climate.temperature.value * 10) / 10)}
              max={(climate.temperature.value * 1.3)}
              steps="3"
              format="°C"
              size="large"
              height="200"
            />
          </Col>
          <Col xl="auto" md="auto">
            <h4>temperature</h4>
            {
              new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: 'Europe/Sofia'
              }).format(new Date(climate.temperature.created_at))
            }:&nbsp;
            <strong>{(Math.round(climate.temperature.value * 10) / 10)}°C</strong>
            <h4>humidity</h4>
            {
              new Intl.DateTimeFormat("en-GB", {
                year: "numeric",
                month: "long",
                day: "2-digit",
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                timeZone: 'Europe/Sofia'
              }).format(new Date(climate.humidity.created_at))
            }:&nbsp;
            <strong>{climate.humidity.value}%</strong>
          </Col>
        </Row>
      </Container>
      <Container id="about" fluid>
        {
          content.copy.blurbs.slice(0, 1).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
              {
                blurb.copy.map((paragraph, paragraphIndex) => (
                  <Col xl={(12 / blurb.copy.length)} key={paragraphIndex}>
                    <Image src={paragraph.image} />
                    <p>{paragraph.text}</p>
                  </Col>
                ))
              }
              </Row>
            </Container>
          ))
        }
      </Container>
      <Container id="security" fluid>
        <Container>
          <Row className="justify-content-xl-center justify-content-md-center">
            {
              content.copy.blurbs.slice(1, 2).map((blurb, blurbIndex) => (
                <Col xl={7} key={blurbIndex}>
                  <h3>{blurb.title}</h3>
                  {
                    blurb.copy.map((paragraph, paragraphIndex) => (
                      <p key={paragraphIndex}>
                        {paragraph}
                      </p>
                    ))
                  }
                  <LinkContainer to="/booking/new">
                    <Button>book now</Button>
                  </LinkContainer>
                </Col>
              ))
            }
          </Row>
        </Container>
      </Container>
      <Container id="lair">
        <h3>
          choose your lair
        </h3>
        <Row className="justify-content-xl-center justify-content-md-center">
          {
            content.copy.cards.slice(0, 3).map((card, cardIndex) => (
              <Col key={cardIndex}>
              <Card className="h-100">
                <Card.Header>
                  {card.title}
                </Card.Header>
                <div style={{height: '200px', backgroundImage: 'url(' + lairImages[cardIndex] + ')'}}>
                  <Image src={lairIcons[cardIndex]} alt={card.image.alt} style={{marginTop: '25px'}} />
                </div>
                <Card.Body>
                  <Card.Title>
                    {card.description.join(' ')}
                  </Card.Title>
                  <hr />
                  <ul style={{lmargin: 0, padding: 0}}>
                    {
                      card.features.map((feature, featureIndex) => (
                        <li key={featureIndex} style={{listStyleType: 'none', fontWeight: 600, margin: 0, padding: 0}}>
                          {feature.text}
                          <ul style={{lmargin: 0, padding: 0}}>
                            {
                              feature.details.map((detail, detailIndex) => (
                                <li key={detailIndex} style={{listStyleType: 'none', fontWeight: 'normal', margin: 0, padding: 0}}>
                                  {detail}
                                </li>
                              ))
                            }
                          </ul>
                        </li>
                      ))
                    }
                  </ul>
                </Card.Body>
                <Card.Footer>
                  <LinkContainer to={card.button.link}>
                    <Button>{card.button.text}</Button>
                  </LinkContainer>
                </Card.Footer>
              </Card>
              </Col>
            ))
          }
        </Row>
      </Container>
      <Container id="adaptive" fluid>
        {
          content.copy.blurbs.slice(2, 3).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
                <Col xl={7}>
                  <p>{blurb.copy[0]}</p>
                </Col>
              </Row>
              <blockquote className="blockquote">
                <p>{blurb.copy[1]}</p>
              </blockquote>
            </Container>
          ))
        }
      </Container>
      <Container id="bike" fluid>
        {
          content.copy.blurbs.slice(3, 4).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
                <Col xl={8}>
                  <p>{blurb.copy[0]}</p>
                </Col>
              </Row>
            </Container>
          ))
        }
        <Container>
          <Row>
            {
              content.copy.cards.slice(3, 6).map((card, cardIndex) => (
                <Col key={cardIndex}>
                  <Card className="h-100">
                    <Card.Header>
                      {card.title}
                    </Card.Header>
                    <div style={{height: '200px', backgroundImage: 'url(' + bikeImages[cardIndex] + ')'}}>
                      <Image src={bikeIcons[cardIndex]} alt={card.image.alt} style={{marginTop: '25px'}} />
                    </div>
                    <Card.Body>
                      <Card.Title>
                        {card.description.join(' ')}
                      </Card.Title>
                      <hr />
                      <ul style={{lmargin: 0, padding: 0}}>
                        {
                          card.features.map((feature, featureIndex) => (
                            <li key={featureIndex} style={{listStyleType: 'none', fontWeight: 600, margin: 0, padding: 0}}>
                              {feature.text}
                            </li>
                          ))
                        }
                      </ul>
                    </Card.Body>
                    <Card.Footer>
                      <LinkContainer to={card.button.link}>
                        <Button>{card.button.text}</Button>
                      </LinkContainer>
                    </Card.Footer>
                  </Card>
                </Col>
              ))
            }
          </Row>
        </Container>
      </Container>
      <Container id="facility" fluid>
        {
          content.copy.blurbs.slice(4, 5).map((blurb, blurbIndex) => (
            <Container key={blurbIndex}>
              <h3>{blurb.title}</h3>
              <Row className="justify-content-xl-center justify-content-md-center">
              {
                blurb.copy.map((paragraph, paragraphIndex) => (
                  <Col xl={(12 / blurb.copy.length)} key={paragraphIndex}>
                    <Image src={paragraph.image} />
                    <p>{paragraph.text}</p>
                  </Col>
                ))
              }
              </Row>
              <Row className="justify-content-xl-center justify-content-md-center">
                <Col xl="auto" md="auto">
                  <LinkContainer to="/booking/new">
                    <Button>book now</Button>
                  </LinkContainer>
                </Col>
              </Row>
            </Container>
          ))
        }
      </Container>
      <Container id="map" fluid>
        <div style={{ height: '650px', width: '100%' }}>
          {
            (window.location.hostname === 'localhost')
              ? null
              : (
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBN2wgWaFrzNnilYKzCBg8y_8l4Q_6Wjeg' }}
                    defaultCenter={{ lat: 41.820582, lng: 23.478257 }}
                    defaultZoom={ 14 }
                    onGoogleApiLoaded={({map, maps}) => {
                      let marker = new maps.Marker({
                        position: { lat: 41.820582, lng: 23.478257 },
                        map,
                        title: 'Bears Lairs, Bansko',
                        description: 'secure, self-storage in bansko with access at your convenience',
                        link: {
                          url: 'https://www.google.com/maps/place/Bears+Lairs/@41.8223813,23.4681867,15z/data=!4m5!3m4!1s0x0:0xadd4ea4c0b9a3216!8m2!3d41.820631!4d23.478215',
                          text: 'maps.google.com/Bears+Lairs'
                        }
                      });
                      let infoWindow = new maps.InfoWindow({
                        content: '<h4><img src="/img/favicon-32x32.png" style="margin-right: 6px;" class="rounded-circle" />' + marker.title + '</h4><p>' + marker.description + '<br /><a href="' + marker.link.url + '">' + marker.link.text + '</a></p>'
                      });
                      infoWindow.open(map, marker);
                      marker.addListener('click', () => {
                        map.setZoom(14);
                        map.setCenter(marker.getPosition());
                        infoWindow.open(map, marker);
                      });
                    }} />
                )
          }
        </div>
      </Container>
    </>
  );
}
