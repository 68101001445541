import React, { useState } from 'react';
import { API, Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import LoaderButton from '../components/LoaderButton';
import { useAppContext } from '../libs/contextLib';
import { useFormFields } from '../libs/hooksLib';
import { onError } from '../libs/errorLib';


const providerId = 'e986bca0-ff41-11ea-8196-af25e422bd08';
const facilityId = 'ff1694c0-ffa8-11ea-bfbc-4f270cb046f3';


export default function Login() {
  const history = useHistory();
  const { userHasAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({ email: '', password: '' });

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);

      // create and associate a stripe customer id if user doesn't have one
      let user = await Auth.currentAuthenticatedUser();
      let { attributes } = user;
      if (!('custom:stripe_customer_id' in attributes)) {
        API.put('limelockers', `/${providerId}/${facilityId}/customer`, { body: { email: fields.email } })
          .then(async(response) => {
            if (response.status && response.customer && response.customer.id) {
              await Auth.updateUserAttributes(user, { 'custom:stripe_customer_id': response.customer.id });
            } else if (response.error) {
              console.log(response.error);
            }
          });
      }

      history.push('/');
    } catch (e) {
      onError(e);
      setIsLoading(false);
    }
  }


  return (
    <Card id="login">
      <Card.Header>
        Already a Customer?
        <span className="float-right">Log In</span>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="email">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              onChange={handleFieldChange} />
          </Form.Group>
          <Form.Group controlId="password">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              onChange={handleFieldChange} />
          </Form.Group>
          <LoaderButton
            className="float-right"
            type="submit"
            isLoading={isLoading}
            disabled={!validateForm()}>
            log-in
          </LoaderButton>
          <Link to="/login/reset">forgot password?</Link>
        </Form>
      </Card.Body>
    </Card>
  );
}
