import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import Container from 'react-bootstrap/Container';
import markdownFile from '../terms.md';

export default function Terms() {
  const [markdownContent, setMarkdownContent] = useState(undefined);
  useEffect(() => { fetch(markdownFile).then(reader => reader.text()).then(setMarkdownContent); }, []);
  return (
    <Container id="policy">
      <ReactMarkdown source={markdownContent} />
    </Container>
  );
}