import React from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import Home from './containers/Home';
import Privacy from './containers/Privacy';
import Terms from './containers/Terms';
import Login from './containers/Login';
import Settings from './containers/Settings';
import ChangeEmail from './containers/ChangeEmail';
import ResetPassword from './containers/ResetPassword';
import ChangePassword from './containers/ChangePassword';
import Signup from './containers/Signup';
import CreateBooking from './containers/CreateBooking';
import BookingList from './containers/BookingList';
import Profile from './containers/Profile';
import NotFound from './containers/NotFound';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path="/privacy">
        <Privacy />
      </Route>
      <Route exact path="/terms">
        <Terms />
      </Route>
      <UnauthenticatedRoute exact path="/login">
        <Container id="login">
          <Row>
            <Col>
              <Login />
            </Col>
            <Col>
              <Signup />
            </Col>
          </Row>
        </Container>
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/login/reset">
        <ResetPassword />
      </UnauthenticatedRoute>
      <UnauthenticatedRoute exact path="/signup">
        <Container id="signup">
          <Row>
            <Col>
              <Login />
            </Col>
            <Col>
              <Signup />
            </Col>
          </Row>
        </Container>
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path="/book">
        <CreateBooking />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/bookings">
        <BookingList />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/profile">
        <Profile />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings">
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/email">
        <ChangeEmail />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path="/settings/password">
        <ChangePassword />
      </AuthenticatedRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
