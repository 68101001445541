import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import 'typeface-roboto';
import App from './App';
import { Amplify } from 'aws-amplify';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import config from './config';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFilePdf, faUser as farUser } from '@fortawesome/free-regular-svg-icons';
import { faPhone, faMapMarkedAlt, faUser as fasUser } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faWhatsappSquare, faViber } from '@fortawesome/free-brands-svg-icons';
//import { fas } from '@fortawesome/free-solid-svg-icons';
//import { fab } from '@fortawesome/free-brands-svg-icons';
import * as serviceWorker from './serviceWorker';

//library.add(fas, fab);
library.add(faPhone, faFacebook, faWhatsappSquare, faViber, faFilePdf, faMapMarkedAlt, farUser, fasUser);

const stripePromise = loadStripe(config.STRIPE_KEY);

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: 'limelockers',
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION
      }
    ]
  }
});

ReactDOM.render(
  <Router>
    <Elements stripe={stripePromise}>
      <App />
    </Elements>
  </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
