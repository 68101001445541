const staging = {
  STRIPE_KEY: 'pk_test_51GskzHLWkJbcmCTlYQ1pisYlrbICWZ8bWVTNVIvU3hS6XYTYH3ls2PaxikdzE8zmJLr5VrsSp1gxTkqkMm5DV8Zf00VYAsg3zL',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://hy1sab4b1f.execute-api.us-east-1.amazonaws.com/dev'
  },
};

const production = {
  STRIPE_KEY: 'pk_test_51GskzHLWkJbcmCTlYQ1pisYlrbICWZ8bWVTNVIvU3hS6XYTYH3ls2PaxikdzE8zmJLr5VrsSp1gxTkqkMm5DV8Zf00VYAsg3zL',
  apiGateway: {
    REGION: 'us-east-1',
    URL: 'https://hy1sab4b1f.execute-api.us-east-1.amazonaws.com/dev'
  },
};

const config = window.location.hostname === 'bearslairs.eu'
  ? production
  : staging;

export default {
  env: (window.location.hostname === 'bearslairs.eu') ? 'production' : 'staging',
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'bearslairs-api-dev-serverlessdeploymentbucket-1tt1ibsw7svan'
  },
  cognito: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_nhmWSzeBZ',
    APP_CLIENT_ID: '6ejs5t57eraer0urepjm6lmi0h',
    IDENTITY_POOL_ID: 'us-east-1:06ca8ae5-f4de-4373-8a72-de5637b22559'
  },
  ...config
};
