import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Auth } from 'aws-amplify';
//import { useHistory } from 'react-router-dom';

export default function Profile() {
  //const history = useHistory();
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    if (!user) {
      Auth.currentAuthenticatedUser()
        .then(_user => {
          setUser(_user);
        })
        .catch(console.log);
    }
  });

  return (!!user)
    ? (
        <Container id="profile">
          <h2>Profile</h2>
          <Row>
            <Col className="text-right">
              name:
            </Col>
            <Col>
              {user.attributes.name}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              email:
            </Col>
            <Col>
              {user.attributes.email}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              phone:
            </Col>
            <Col>
              {user.attributes.phone_number}
            </Col>
          </Row>
          <Row>
            <Col className="text-right">
              address:
            </Col>
            <Col>
              {user.attributes.address}
            </Col>
          </Row>
        </Container>
      )
    : null;
}