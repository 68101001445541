import React from 'react';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

export default function LoaderButton({
  isLoading,
  disabled = false,
  ...props
}) {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      {isLoading && <Spinner animation="border" size="sm" style={{marginRight: '0.7em'}} />}
      {props.children}
    </Button>
  );
}
